import classes from "./BuisnessParter.module.css";
import image from "./Images/shake hand.png";

function BuisnessParter () {
    return(
       <div className={classes.container}>
        <div className={classes.heroimg}><img src={image} alt=""></img></div>
        
       </div>
    );
};
export default BuisnessParter;