import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./Navbar.css";
import Services from "./Services";

import logo1 from "./Images/shauryalogo.png";
import logo2 from "./Images/realvalue.png"

function Navbar() {
  const [show, setShow]= useState(false);

  const clkHnd=()=>{
    setShow(!show);
  }



  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  return (
    < >
      <nav className="navbar">
        <div className="nav-container">
          <NavLink exact to="/" className="nav-logo1">
            <img src={logo1} alt=""></img>
          </NavLink>

          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <NavLink
                exact
                to="/"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              ><i className="fa-solid fa-house"></i>
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/about"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              ><i className="fa-solid fa-address-card"></i>
                About Us
              </NavLink>
            </li>
            <li className=" ak1" onClick={clkHnd} >
              <NavLink
                exact
                to=""
                activeClassName="active2"
                className="nav-links"
                onClick={handleClick}
              ><i className="fa-solid fa-list"></i>
                Our Services <i class="fa-solid fa-caret-down"></i>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/emicalculator"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              ><i className="fa-solid fa-calculator"></i>
                Emi Calculator
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/contact"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              ><i className="fa-solid fa-address-book"></i>
                Contact Us
              </NavLink>
            </li>
          </ul>
          <div className="nav-icon" onClick={handleClick}>
            <i className={click ? "fas fa-times" : "fas fa-bars"}></i>
          </div>
          <div className="nav-logo1">
            <img src={logo2} alt=""></img>
          </div>
        </div>
        
      </nav>
      {show && <Services />}
    </>
  );
}

export default Navbar;