import classes from "./Hero1.module.css";
import { Link } from "react-router-dom";

function Hero1(props){
    return(
        <div className={classes.container}>
        <div className={classes.head}><h1>{props.head}</h1></div>
        <div className={classes.text}><p>{props.text}</p></div>
        <div className={classes.btn}><button className={classes.btnmob}><Link to={props.link} className={classes.linkstyle}>{props.btntext}</Link></button></div>
        </div>
      
    );
};
export default Hero1;