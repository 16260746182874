import Footer from "../Components/Footer";
import Header from "../Components/Header";
import Navbar from "../Components/Navbar";
import AboutCo from "../Components/AboutCo";
import WatsappFloat from "../Components/WatsappFloat";



function About (){
    return(
        <>
        <Header/>
        <Navbar/>
        
       <AboutCo/>
       <WatsappFloat/>
        <Footer/>
        </>
    );
};
export default About;