import classes from "./CompanyProfile.module.css";
import image1 from "./Images/awards.jpg";

function CompanyProfile(){
    return(
        <div className={classes.container}>
            <div className={classes.left}>
                <div className={classes.head}><h1>Company Profile</h1></div>
                <div className={classes.linehlder}><span className={classes.divBar}></span> </div>
                <div className={classes.paragraph}><p>Shaurya Enterprises is associated Channel Partner of Real Value Finloan Services pvt.ltd.  established in 2014 at Wakad , Pune  is led by expert professionals who have more than 25 years’ experience in the market. We are Authorized Business Associates with leading banks and the top financial institutions. Our monthly disbursement run rate is upwards of Rs 20 cr & 100 Files.

Our consistency in maintaining a strong rapport with Chartered Accountants, Builders, reputed Real Estate Agents, Human Resource Heads, HNls, Bankers, as also, with Business Partners and Referral Partners has seen us grow consistently. Our reputation for transparency, quality service and timely delivery is next to none - and we continuously work to better our processes to do even better.

We, at Shaurya Enterprises aim to provide comprehensive financial loan services to business, organizations, individuals & others who seek our expertise.</p></div>
            </div>
            <div className={classes.right}>
                <img src={image1}></img>
            </div>
        </div>

    );
};
export default CompanyProfile;