import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Hero1 from "./Hero1";
import classes from "./Hero1Slider.module.css";

export default class SimpleSlider extends Component {
    render() {
      const settings = {
        dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      speed: 4000,
      autoplaySpeed: 3000,
      cssEase: "linear"
      };
      return (
        <div className={classes.container}>
          
          <Slider {...settings}>
            <Hero1  head="Shaurya Enterprises"
            text="Turn Your Dreams Into Reality .
            Most Ethical and Transparent Company."
            link='/about'
            
            btntext="Learn More" />
            
            
            <Hero1 head="Get Your Personal Loan at Best Plan"
            text="Personal loan to customer for salaried and Pensioner"
            btntext="Learn More" 
            link='/personalLoan'/>
            <Hero1 head="Achieve Your Dream Wedding with Shaurya Enterprises!"
            text="Dreaming of a picture-perfect wedding but worried about the costs? Look no further than Shaurya Enterprises."
            btntext="Learn More"
            link='/personalLoan' />
            <Hero1 head="Turn Your House Into a Home with Shaurya Enterprises!"
            text="Ready to transform your house into your dream home? Shaurya Enterprises can make it possible! Whether you're planning to renovate, remodel, or add new features, our home improvement loans are tailored to meet your needs."
            btntext="Learn More" 
            link='/homeLoan'/>
            <Hero1 head="Drive Your Dream Car with Shaurya Enterprises!"
            text=" Whether you're buying a new or used vehicle, our competitive auto loans provide the financial support you need. Say goodbye to compromising on your dream car and hello to driving in luxury and comfort."
            btntext="Learn More"
            link="/personalLoan" />
            <Hero1 head ="Are you eligible for loan?"
            text ="In today's world, almost everyone opts for a home loan when it comes to buying a house. Shaurya Enterprises allows you to calculate the home loan eligibility at ease."
            btntext="Calculate Your Loan"
            link='/emicalculator'/>
          </Slider>
        </div>
      );
    }
  }