import classes from "./BuisnessPartnerProgram.module.css";
import Header from "./Header";
import Navbar from "./Navbar";
import image from "../Components/Images/buisness_partner_program.jpg";
import Footer from "./Footer";

function BuisnessPartnerProgram (){
    return(
        <div className={classes.mainAll}>
            <Header/>
            <Navbar/>
            <div className={classes.bg}>
                <h1>Business Partner Program</h1>
            </div>
            <div className={classes.container}>
                <div className={classes.left}>
                    <h1>Introduction</h1>
                    <p>We want to associate with full time business partners for our various service sections.
                        <br></br>Are you looking for growth in your current annual revenue with no upfront costs?.
                        <br></br>Introducing the Business Partner Program by Shaurya Enterprises. A program for individuals who wish to become partners with us for a long term good monetary bond. From bringing in the prospects to converting them except for banking procedures, everything will be dealt by our business partners.
                    </p>
                    <br></br>
                    <p><i class="fa-regular fa-hand-point-right"></i> No investment required</p>
                    <p><i class="fa-regular fa-hand-point-right"></i> Benefit to use fully equipped office</p>
                    <p><i class="fa-regular fa-hand-point-right"></i> Support by the experts for individual growth</p>
                    <p><i class="fa-regular fa-hand-point-right"></i> Investing for business done in some banks & products</p>
                    <br></br>
                    <p>If you think you have the potential to come on board with us as our BusinessChannel partner, do give us a call.</p>
                    <p>Let’s grow together!</p>
                </div>
                <div className={classes.right}>
                    <img src={image} alt=""></img>
                </div>
            </div>
            <Footer/>
        </div>

    );
};
export default BuisnessPartnerProgram;