import Header from "../../Header";
import Navbar from "../../Navbar";
import classes from "./PersonalLoan.module.css";
import image from "./asset/personal_loan.jpg";
import { useState } from 'react';
import './Acc.css';
import icon from './asset/next.png';
import Footer from "../../Footer";
function PersonalLoan(){
    const [r1, setR1] = useState(true);
    const [r2, setR2] = useState(false);
    const [r3, setR3] = useState(false);
    const [r4, setR4] = useState(false);
    const [r5, setR5] = useState(false);

    const clkHnd1 = () => {
        setR1(true);
        setR2(false);
        setR3(false);
        setR4(false);
        setR5(false);
    };

    const clkHnd2 = () => {
        setR1(false);
        setR2(true);
        setR3(false);
        setR4(false);
        setR5(false);
    };

    const clkHnd3 = () => {
        setR1(false);
        setR2(false);
        setR3(true);
        setR4(false);
        setR5(false);
    };

    const clkHnd4 = () => {
        setR1(false);
        setR2(false);
        setR3(false);
        setR4(true);
        setR5(false);
    };

    const clkHnd5 = () => {
        setR1(false);
        setR2(false);
        setR3(false);
        setR4(false);
        setR5(true);
    };
    return(
        <div className={classes.allMain}>
            <Header/>
            <Navbar/>
            <div className={classes.bg}>
                <h1>Personal Loan</h1>
            </div>
            <div className={classes.container}>
                <div className={classes.left}>
                    <h1>Introduction</h1>
                    <p>It is Shaurya Enterprises endeavour to make getting a loan easier for you by doing an instant credit evaluation and connecting you with the right lender. We provide everyone with easy and convenient access to credit.
                        <br></br>Personal Loan is a part of unsecured loan as it does not require any security against it. It is best loan to meet your current and immediate financial requirements. Personal Loans are ideal for those who need quick and easy loan with manageable Interest rates and minimum documentation.
                        <br></br>The personal loan provides you with the feasible channel to use the funds as per client convenience and need. Maintaining a decent credit score and a clean credit history are the key factors to receive personal loan easily.
                        <br></br>Personal loan gives the power to the consumer to fulfil their needs and demands.
                    </p>
                    <h4>We Care For Your Needs</h4>
                    <p>We know you are concerned to know the exact EMI that you will have to pay for taking a personal loan from any bank . You can give necessary loan requirement details and can accordingly get the information of how much would be the EMI for a particular amount and for a particular tenure.
                        <br></br>Once you okay with that, you can then fill out the application form and our representative will be there to collect the same. Once we receive your application, we will take care of the rest until the loan amount is disbursed into your account.
                    </p>
                </div>
                <div className={classes.right}>
                    <img src={image} alt=""></img>
                </div>
            </div>
            <div className='AccHold'>
            <div className='left'>
                <button className={r1 ? 'AccBtn1 AccBtn11' : 'AccBtn'} onClick={clkHnd1}>Advantages Of Personal Loans?</button>
                <button className={r2 ? 'AccBtn1' : 'AccBtn'} onClick={clkHnd2}>Where Can You Use Proceeds Of PL</button>
                <button className={r3 ? 'AccBtn1' : 'AccBtn'} onClick={clkHnd3}>How can Apply With Shaurya?</button>
                <button className={r4 ? 'AccBtn1' : 'AccBtn'} onClick={clkHnd4}>Eligibility Criteria</button>
                <button className={r5 ? 'AccBtn1' : 'AccBtn'} onClick={clkHnd5}>Documentation Required</button>
            </div>
            <div className='right'>

                {r1 && <div className='right1'>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>No Security</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Minimum Documents</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Fast Process</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Easy Repayments</p>
                    </div>
                    
                </div>}

                {r2 && <div className='right2'>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>To contribute own marriage or marriage in the family.</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>For a medical emergency.</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>for your Childs' education.</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Higher Studies.</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Home Improvement.</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>To contribute in Small Business Use.</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Clear Credit Card Debt.</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Weekend Getaway.</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Gift your loved ones.</p>
                    </div>
                </div>}

                {r3 && <div className='right3'>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Go to www.shauryaenterprisesfin.com</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Click on Personal Loans.</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Fill the form and submit it.</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Our representative will contact you to explain you process and subsequently collect the form.</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Transparency</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Done, in 3-4 working days, you will get the Personal Loan in your bank account.</p>
                    </div>
                </div>}

                {r4 && <div className='right3'>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Applicant should be a citizen of India</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Should be occupying his current place of residence for a minimum of 3 years in the city</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Applicants should atleast be 24 years of age</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>If the applicant is self employed, he should be in the business for atleast 3 years, if he/she is salaried, he should have minimum 3 years of Work Experience</p>
                    </div>
                   
                </div>}

                {r5 && <div className='right3'>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Financials:-Income Details: IT returns, Balance sheet P/L account statement for the last 2 years with Annexure, Form 16 A</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Bank Statements:-Latest 6months Business account bank statement</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Employment Proof:-Certificate of Practice</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Identity Proof:-Passport / Voters ID card / Driving license / PAN card</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Address Proof:-Passport / Driving license / Ration card / Latest mobile bill / Letter from employer (with HR / Admin sign)</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Signature Proof:-Passport / PAN card / Driving license / Photo credit card (front only) / Banker verification letter (only online)</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Age Proof:-Passport / PAN card / Driving license / Birth certificate</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Self Employed Professionals</p>
                    </div>
                </div>}
            </div>
        </div>
        <Footer/>
        </div>

    );
};
export default PersonalLoan;