import classes from './AboutCo.module.css';
import a1 from './asset/about1.jpg';
import AdvCard from './AdvCard';
import clock from './asset/clock.png';
import bnk from './asset/bank.png';
import mul from './asset/team.png';
import per from './asset/100-percent.png';
import chk from './asset/check.png';
import { Link } from "react-router-dom"; 

import Card from './Card';

const About = () => {
    return (
        <div className={classes.hold}>
            <div className={classes.headhold}>About Us</div>

            
            <div className={classes.introhold}>

                <div className={classes.introL}>
                <h1 className={classes.introhead}>Introduction</h1>
                <p>Shaurya Enterprises is associated Channel Partner of Real Value Finloan Services pvt.ltd. established in 2014 at Wakad , Pune is led by Mr. Sagar Jagtap and expert professionals who have more than 25 years’ experience in the market. We are Authorized Business Associates with leading banks and the top financial institutions. Our monthly disbursement run rate is upwards of Rs 20 cr & 100 Files.   </p>
                    <p>In the recent past, we have witnessed a phenomenal surge of finance for home purchases as well as business expansion. At Shaurya Enterprises, we have kept pace with this growth. Our growth is mainly fuelled by our deep understanding of client needs and banking products, along with our unbiased effort to bring a win-win deal both for the client as well as the bank. Clients continue to appreciate our best-in-class service and professional interactions, along with the use of latest technology to keep clients updated on the progress of their transaction.</p>
                    
                    <p>While the numbers tell their own story, we take pride in having achieved these by serving the varied needs of thousands of customers spread over diverse geographies. This experience stands us in good stead whenever our customers have specific needs in terms of guidance, processes, possibilities, and of course, loans.</p>
                    
                </div>
                <div className={classes.introR}>
                    <img className={classes.introImg} src={a1}></img>
                </div>
            </div>
             <div className={classes.ha}>
            <p className={classes.introhead2}>Advantages Of Dealing With Shaurya Enterprises</p>
            <p className={classes.introhead3}>Here Is An Overview To The Advantages Of Dealing With Shaurya Enterprises For Customers</p>
            <p className={classes.introhead4}>Our consistency in maintaining a strong rapport with Chartered Accountants, Builders, reputed Real Estate Agents, Human Resource Heads, HNls, Bankers, as also, with Business Partners and Referral Partners has seen us grow consistently. Our reputation for transparency, quality service and timely delivery is next to none - and we continuously work to better our processes to do even better.</p>
            </div>
            <div className={classes.bgimg}>
                <div className={classes.r}>
                <div className={classes.top }>
                    <Card head='Knowledge-Sharing' txt='Our sales team is clued in to the latest changes in products and services'></Card>
                    <Card head='One-Stop-Solution' txt="We ensure the solutions to the client' s needs are presented all the possibilities"></Card>
                    <Card head='Delivering Efficiency' txt='Our strong relationship with the lenders ensures our clients get Preferential treatment.'></Card>
                </div>

                <div className={classes.top}>
                    <Card head='Experienced Outlook' txt='Our sales team is clued in to the latest changes in products and services'></Card>
                    <Card head='Relationship Plus' txt='We have received Award for Top Performing channel partner from SCB, Centurian Bank of Punjab,'></Card>
                    <Card head='Product Portfolio' txt='Home Loans, Loans against Property, Commercial Purchases,'></Card>
                </div>
                </div>
            </div>

            <p className={classes.advHead}>Advantages of joining as Referral Partner</p>
            <div className={classes.adv}>

                <AdvCard image={clock} txt='Better & Timely Payout.'></AdvCard>
                <AdvCard image={bnk} txt="Solution From Various Private Sector Banks & NBFC' s"></AdvCard>
                <AdvCard image={mul} txt='Offering Of Multiple Products'></AdvCard>
                <AdvCard image={per} txt="100% Transparency Is Maintained Throughout The Transaction"></AdvCard>
                <AdvCard image={chk} txt="For Sole Selling Agents We Can Get Builder Project Approved From Multiple Banks & Nbfc's"></AdvCard>
            </div >

            <div className={classes.grow}>
                <p className={classes.growTxt}>Let's grow together!</p>
                <button className={classes.growBtn}><Link to="/contact">CONTACT US</Link></button>
            </div>
        </div >
    )
}

export default About;