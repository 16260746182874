import classes from "./EmiCalculatorAdd.module.css";

function EmiCalculatorAdd(){
    return(
        <div className={classes.container}>
            <div className={classes.bg}></div>
            <div className={classes.heading}><h1>EMI Calculator</h1></div>
        </div>

    );
};
export default EmiCalculatorAdd;