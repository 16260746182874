import classes from "./HeroIcon.module.css";

function HeroIcon(){
    return(
        <div className={classes.heroIcon}><i className="fa-sharp fa-solid fa-building-columns"></i>
        </div>
        

    );
};
export default HeroIcon;