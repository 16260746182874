import ContactAdd from "../Components/ContactAdd";
import Header from "../Components/Header";
import Navbar from "../Components/Navbar";
import Contact from "../Components/Contact";
import Footer from "../Components/Footer";
import ContactText from "../Components/ContactText";
import WatsappFloat from "../Components/WatsappFloat";

function ContactPage (){
    return(
        <>
        <Header/>
        <Navbar/>
        <ContactAdd/>
       < ContactText/>
        <Contact/>
        <WatsappFloat/>
        <Footer/>
        </>
    );
};
export default ContactPage;