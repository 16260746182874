import classes from "./WhyUs.module.css";
import image from "./Images/whyUs.jpg";

function WhyUs (){
    return(
    <div className={classes.container}>
        
        
        <div className={classes.row}>
        <div className={classes.left}><img src={image} alt=""></img></div>
        <div className={classes.right}>
        <div className={classes.head}><h1>Why Us</h1></div>
        <div className={classes.linehlder}><span className={classes.divBar}></span> </div>
            <p className={classes.cont}>At Shaurya Enterprises, we have kept in pace with our ever-increasing growth. Our growth is mainly fueled by our deep understanding of client needs and banking products, along with our unbiased effort to bring a win-win deal both for the client as well as the bank. Clients always appreciate our best-in-class service and professional interactions, along with the use of latest technology to keep clients updated on the progress of their transaction.

While the numbers tell their own story, we take pride in having achieved these by serving the varied needs of thousands of customers spread over diverse geographies. This experience stands us in good stead whenever our customers have specific needs in terms of guidance, processes, possibilities, and of course, loans.</p></div>
        </div>
        
    </div>
    );
};
export default WhyUs;