import classes from './LoanAgainstProperty.module.css';
import image from './asset/loan_against_property.jpg';
import one from './asset/number-one.png';
import two from './asset/two.png';
import three from './asset/number-3.png';
import arrow from './asset/right-arrow.png';
import exam from './asset/exam.png';
import cursor from './asset/cursor.png';
import http from "./asset/http.png";
import Footer from '../../Footer';
import Header from "../../Header";
import Navbar from "../../Navbar";
//<i classname="fa-regular fa-hand-point-right">
import Acc from '../Service/Acc';
const LoanAgainstProperty = () => {
    return (
        <div className={classes.mainAll}>
            <Header/>
            <Navbar/>
        <div className={classes.hold}>
            <div className={classes.headhold}>
                <p className={classes.head}>Loan Against Property</p>
            </div>

            
            <div className={classes.hold2}>
                <div className={classes.left}>
                <h1 className={classes.intro}>Introduction</h1>
                    <p className={classes.leftTxt}>This is one of the easiest ways you can raise money i.e. by keeping your property (residential/commercial) as a guarantor for availing a loan. That means your own property helps you in getting a loan.</p>
                    <p className={classes.leftTxt}>Loan against property is a secured loan. This is because a property is been handed over to that bank by the borrower acting as a guarantee. LAP is very secure as per customers point of view. It adds credibility to the customer. Because of that bank or any financial institute will not question the customer/client repayment capability. Shaurya Enterprises makes LAP available at the affordable rates.</p>
                    <p className={classes.lefthead}>This is a secured loan so :-</p>
                    <p className={classes.leftTxt}><i class="fa-regular fa-hand-point-right"></i>EMI is lower</p>
                    <p className={classes.leftTxt}><i class="fa-regular fa-hand-point-right"></i>Cheaper Interest Rate</p>
                    <p className={classes.leftTxt}><i class="fa-regular fa-hand-point-right"></i>Longer Tenure</p>
                    <p className={classes.leftTxt}><i class="fa-regular fa-hand-point-right"></i>Maximum amount possible given as loan amount</p><br></br>
                    <p className={classes.leftTxt}>The tenure of LAP is more compare to personal loan. Bank checks all the documents related to property that the borrower is providing for the loan. Properties that can use in LAP are mostly self-occupied or rented residential property. It can be a house or even a piece of land.</p>
                    <p className={classes.leftTxt}>In LAP, ownership of that property which the consumer has handed over as a collateral, it always remains with that consumer, unlike in Home Loan in which the ownership is completely transferred to the Financial Institute/Bank. The consumer doesn't have to repay the whole loan to get the ownership. At any point intime, if the consumer is not able to repay the loan amount, he/she can sell the property accordingly. The amount that he receives by selling that land, through thatsettlement of the loan can be done. When the repayment of the loan is completed with thecharged interest the consumer gets his property back.</p>
                </div>

                <div className={classes.right}>
                    <img src={image} className={classes.image}></img>
                </div>
            </div>

            <div className={classes.processHold}>
                <h1 className={classes.processhead}>Process</h1>
                <p className={classes.processhead2}>How can you apply with Shaurya Enterprises?</p>
            </div>

            <div className={classes.processSteps}>
                <div className={classes.processStepsIn}>
                    <img src={http} className={classes.processIcon}></img>
                    <img src={cursor} className={classes.processIcon}></img>
                    <img src={exam} className={classes.processIcon}></img>
                </div>

                <div className={classes.processStepsIn2}>
                    <img src={one} className={classes.processIcon}></img>
                    <img src={arrow} className={classes.processIcon}></img>
                    <img src={two} className={classes.processIcon}></img>
                    <img src={arrow} className={classes.processIcon}></img>
                    <img src={three} className={classes.processIcon}></img>
                </div>

                <div className={classes.processStepsIn3}>
                    <p>Go To www.shauryaenterprisesfin.com</p>
                    <p>Click On Loan Against Property</p>
                    <p>Fill The Form And Submit It</p>
                </div>
            </div>

            <div>
                <Acc></Acc>
            </div>
        </div>
       <Footer/>
        </div>
    )
}

export default LoanAgainstProperty;