import classes from './AdvCard.module.css';
import clk from './asset/clock.png';

const AdvCard = (props) => {
    return (
        <div className={classes.card}>
            <img src={props.image} alt='' className={classes.icon}></img>
            <p className={classes.txt}>{props.txt}.</p>
        </div>
    )
}

export default AdvCard;