import classes from "./Footer.module.css";
import instagram from  "./Images/instagram.png";
import facebook from "./Images/facebook.png";
import linkdin from "./Images/linkedin.png";
import heart from "./Images/heart.gif";

function Footer(){
    return(
        <div className={classes.container}>
            <div className={classes.r1}>
                <div className={classes.socialmedia}>
               <a href="https://www.linkedin.com/company/shaurya-enterprises-financial-consultant/" target="_blank"> <img src={linkdin} /> </a>
                <a href="https://www.facebook.com/shauryaenterprisesfin" target="_blank"><img src={facebook} /></a>
                <img src={instagram}></img>
                </div>
                <div className={classes.address}>
                    <div className={classes.Addrow}>
                <i class="fa-solid fa-location-dot"></i>
                <div className={classes.Addcol}>
                    <p className={classes.name}>Address</p>
                    <p>Office No. 202, 2nd Floor, Sonigara Laurel, Kaspate Wasti, Mankar Chowk, Wakad, Pune 411057.</p>
                    </div>
                    </div>
                </div>
                <div className={classes.contact}>
                <div className={classes.Addrow}>
                <i class="fa-solid fa-phone"></i>
                <div className={classes.Addcol}>
                <p className={classes.name}>Phone</p>
                <p>+91 8554983997</p>
                </div>
                </div>
                </div>
                <div className={classes.email}>
                <div className={classes.Addrow}>
                <i class="fa-solid fa-envelope"></i>
                <div className={classes.Addcol}>
                <p className={classes.name}>Email</p>
                <p>info@shauryaenterprisesfin.com</p>
                
                </div>
                </div>
                </div>
            </div>
            <div className={classes.col}>
                <div className={classes.row}>
            <div className={classes.r2}>
                <div className={classes.headQuick}>Quick Links</div>
                <div className={classes.links}><a href="/">Home</a></div>
                <div className={classes.links}><a href="/about">About Us</a></div>
                <div className={classes.links}><a href="/">Our Services</a></div>
                <div className={classes.links}><a href="/emicalculator">EMI Calculator</a></div>
            </div>
            <div className={classes.r3}>
                <div className={classes.headServices}>Services</div>
                <div className={classes.servicesLinks}><a href="/homeLoan">Home Loan</a></div>
                <div className={classes.servicesLinks}><a href="/personalLoan">Personal Loan</a></div>
                <div className={classes.servicesLinks}><a href="/unsecuredBuisnessLoan">Unsecured Business Loan</a></div>
                <div className={classes.servicesLinks}><a href="/smeloan">SME Loans</a></div>
                <div className={classes.servicesLinks}><a href="/commertialProperty">Loan for Purchase of Commercial Properties</a></div>
                <div className={classes.servicesLinks}><a href="/creditCLone">Credit Card</a></div>
                <div className={classes.servicesLinks}><a href="/generalInsurance">General Insurance</a></div>
                <div className={classes.servicesLinks}><a href="/lifeInsurance">Life Insurance</a></div>
                <div className={classes.servicesLinks}><a href="/constructionFinance">Construction Finance</a></div>
                
            </div>
            </div>
            <div className={classes.copyright}><p>Copyright © 2023 Shaurya Enterprises | Made with <span className={classes.heartImg}><img src={heart} /></span> by CORTICA WEB SOLUTIONS PVT LTD</p></div>
            </div>
            
    </div>

    );
};
export default Footer;