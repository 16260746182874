import classes from "./ContactText.module.css";


function ContactText(){
    return(
        <div className={classes.container}>
            <div className={classes.left}>
                <div className={classes.c1}>
                    <div className={classes.c1l}><i class="fa-solid fa-location-dot"></i></div>
                    <div className={classes.c1r}>
                        <div className={classes.r1}>Address</div>
                        <div className={classes.r2}>Office No. 202, 2nd Floor, Sonigara Laurel, Kaspate Wasti, Mankar Chowk, Wakad, Pune 411057.</div>
                    </div>
                    </div>
                <div className={classes.c1}>
                <div className={classes.c1l}><i class="fa-solid fa-phone"></i></div>
                    <div className={classes.c1r}>
                        <div className={classes.r1}>Phone</div>
                        <div className={classes.r2}>+91 8554983997</div>
                    </div>
                </div>
                <div className={classes.c1}>
                <div className={classes.c1l}><i class="fa-solid fa-envelope"></i></div>
                    <div className={classes.c1r}>
                        <div className={classes.r1}>Email</div>
                        <div className={classes.r2}>info@shauryaenterprisesfin.com</div>
                        
                    </div>
                </div>
            </div>
            
        <div className={classes.right}><iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3781.615707132985!2d73.77122121489373!3d18.5913562873678!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTjCsDM1JzI4LjkiTiA3M8KwNDYnMjQuMyJF!5e0!3m2!1sen!2sin!4v1689224476217!5m2!1sen!2sin"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe></div>
        </div>
    );
};
export default ContactText;