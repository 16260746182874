import Header from "../Components/Header";
import Navbar from "../Components/Navbar";
import Hero from "../Components/Hero";
import Hero1Slider from "../Components/Hero1Slider";
import HeroIcon from "../Components/HeroIcon";
import Review from "../Components/Review";
import EmiHome from "../Components/EmiHome";
import OurServices from "../Components/OurServices";
import CompanyProfile from "../Components/CompanyProfile";
import BuisnessParter from "../Components/BuisnessPartner";
import BuisnessPartnertext from "../Components/BusnessPartnertext";
import ChoicePartner from "../Components/ChoicePartner";
import WhyUs from "../Components/WhyUs";
import Contact from "../Components/Contact";
import Footer from "../Components/Footer";
import WatsappFloat from "../Components/WatsappFloat";




function Home (){
    return(
        <>
        <Header/>
        <Navbar/>
        <Hero/>
        <Hero1Slider/>
        <HeroIcon/>
        <Review/>
        <EmiHome/>
        <OurServices/>
        <BuisnessParter/>
        < BuisnessPartnertext/>
        <CompanyProfile/>
        <WhyUs/>
        <ChoicePartner/>
        <Contact/>
        <WatsappFloat/>
        <Footer/>
        
        
        </>
    );
};
export default Home;