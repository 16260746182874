import Header from "../../Header";
import Navbar from "../../Navbar";
import classes from "./CommertialProperties.module.css";
import image from "./asset/commercial.jpg";
import { useState } from 'react';
import './Acc.css';
import icon from './asset/next.png';
import Footer from "../../Footer";

function CommertialProperties(){
    const [r1, setR1] = useState(true);
    const [r2, setR2] = useState(false);
    const [r3, setR3] = useState(false);
    const [r4, setR4] = useState(false);
    const [r5, setR5] = useState(false);

    const clkHnd1 = () => {
        setR1(true);
        setR2(false);
        setR3(false);
        setR4(false);
        setR5(false);
    };

    const clkHnd2 = () => {
        setR1(false);
        setR2(true);
        setR3(false);
        setR4(false);
        setR5(false);
    };

    const clkHnd3 = () => {
        setR1(false);
        setR2(false);
        setR3(true);
        setR4(false);
        setR5(false);
    };

    const clkHnd4 = () => {
        setR1(false);
        setR2(false);
        setR3(false);
        setR4(true);
        setR5(false);
    };

    const clkHnd5 = () => {
        setR1(false);
        setR2(false);
        setR3(false);
        setR4(false);
        setR5(true);
    };
    return(
        <div className={classes.mainAll}>
            <Header/>
            <Navbar/>
            <div className={classes.bg}>
                <h1>Commercial Properties</h1>
            </div>
            <div className={classes.container}>
                <div className={classes.left}>
                    <h1>Introduction</h1>
                    <p>This is one of the easiest ways you can raise money i.e. by keeping your commercial property commercial as a mortgage for availing a loan. That means your own property helps you in getting a loan.<br></br>

Commercial Property Purchase Loan is often used by business to secure funds for purchase of their new office or shop or retail establishments. This type of loan is available only for commercial borrowers i.e. business entities.<br></br>

Commercial Property Purchase Loan is a secured loan and is often used to purchase commercial premises only as it provides a greater deal of flexibility and easy access to capital. It is like a Home Loan product but the only difference is that Home Loans are for individual people for their residential purchase requirements whereas this type of loan i.e. the Commercial Property Purchase Loan is for business entities for their requirement of purchase of commercial premises. The interest rate is also higher than the Home Loan rate.<br></br>

The criteria which we adopt while evaluation a Commercial Property Purchase Loan proposition is the quality of the collateral, the credit worthiness of the lender, the repaying capacity of the business which can be measured through various financial ratios derived from their balance sheet and profit & loss statements.</p>
                </div>
                <div className={classes.right}>
                    <img src={image} alt=""/>
                </div>
            </div>
            <div className='AccHold'>
            <div className='left'>
                <button className={r1 ? 'AccBtn1 AccBtn11' : 'AccBtn'} onClick={clkHnd1}>Why Shaurya Enterprises</button>
                <button className={r2 ? 'AccBtn1' : 'AccBtn'} onClick={clkHnd2}>How can you apply</button>
                <button className={r3 ? 'AccBtn1' : 'AccBtn'} onClick={clkHnd3}>Eligibility Criteria</button>
                <button className={r4 ? 'AccBtn1' : 'AccBtn'} onClick={clkHnd4}>Documentation Required</button>
                
            </div>
            <div className='right'>

                {r1 && <div className='right1'>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Many tie ups with all leading banks & NBFCs</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Hassle free process & documentation</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Door Step Service</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Maximum Loan Disbursement</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Flexible Repayment Options</p>
                    </div>
                    
                </div>}

                {r2 && <div className='right2'>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Go to www.shauryaenterprisesfin.com</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Click on Commercial Property Purchase Loan.</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Fill the form and submit it.</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Our representative will contact you shortly to arrange a meeting with you and collect the documents for further processing.</p>
                    </div>
                </div>}

                {r3 && <div className='right3'>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Applicant should be a citizen of India</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Individuals who have been in the current business for a minimum of 3 years, with 5 years total business experience.</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Applicants should atleast be 21 years of age while applying the loan</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Applicant should be not more than 65 years of age at the time of maturity of the loan.</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>If the applicant is self employed, he should be in the business for atleast 3 years, if he/she is salaried, he should have minimum 3 years of Work Experience.</p>
                    </div>
                </div>}

                {r4 && <div className='right3'>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Passport-size photographs.</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Identity and signature proof - Passport, Pan card, Driving License, Voter ID card, Aadhar card, employee identity card in case of government employees.</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Address Proof - Bank statement, Rent Agreement, Voter ID card, Ration card, Passport, Driving License, telephone/electricity/water/credit card bill or Property tax.</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Age Proof - Voter ID card, Secondary school leaving certificate (class 10), birth certificate, Passport, Aadhar Card, pension payment order or receipt of LIC policy.</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>IT Returns of the three years preceding the one in which loan is applied for.</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Copy of details of all existing loans.</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Property papers with OC and CC.</p>
                    </div>
                </div>}

                
            </div>
        </div>
        <Footer/>
        </div>

    );
};
export default CommertialProperties;