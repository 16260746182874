import EmiCalculatorAdd from "../Components/EmiCalculatorAdd";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import Navbar from "../Components/Navbar";
import EmiHome from "../Components/EmiHome";
import WatsappFloat from "../Components/WatsappFloat";

function EmiCalculator (){
    return(
        <>
        <Header/>
        <Navbar/>
        <EmiCalculatorAdd/>
        <EmiHome/>
        <WatsappFloat/>
        <Footer/>
        </>
    );
};
export default EmiCalculator;