import classes from "./Contact.module.css";
import {  Link,NavLink } from "react-router-dom";
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

function Contact (){
  function message (){
    var Name = document.getElementById('name');
    var Email = document.getElementById('email');
    var Location = document.getElementById('location');
    var Mobile = document.getElementById('mobile');
    var Profile = document.getElementById('profile');
    var Service = document.getElementById('service');
    var Msg = document.getElementById('msg');
    const Success=document.getElementById('success');
    const Danger=document.getElementById('danger');

    if (Name.value ==='' || Email.value ==='' || Location.value===''||Mobile.value==='' ||
    Profile.value==='' ||Service.value==='' || Msg.value=== ''){
        Danger.style.display ='block';
    }
    else{
        setTimeout(()=> {
           Name.value='';
           Email.value='';
           Location.value='';
           Mobile.value='';
           Profile.value='';
           Service.value='';
        },2000);
        Success.style.display='block';
    }

    setTimeout(()=>{
     Danger.style.display='none';
     Success.style.display='none';
    },4000)
  }



    const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_nkugjjc', 'template_3ect5ah', form.current, 'Rf5mtvk2ZyncdCO-0')
      .then((result) => {
        
        alert("Success! Thank you for the message. We will contact you within 24 Hours.");
         console.log(result.text);
          
      }, (error) => {
          console.log(error.text);
      });
  };
    return(
    <div className={classes.container}>
        <div className={classes.head}><h1>Request a Call Back</h1></div>
        <div className={classes.linehlder}><span className={classes.divBar}></span> </div>
        <div className={classes.bgwhite}>
            <div className={classes.fill}> <h4>Fillup The Form</h4></div>
            <form ref={form} onSubmit={sendEmail}>
        <div className={classes.form}>
            
            <div className={classes.r1}>
            <input type="text" name="user_name" id="name" placeholder="Enter Your Full Name" required></input>
            <input type="email" name="user_email" id="email" placeholder="Enter Your Email" required></input>
            </div> <br></br>
            <div className={classes.r2}>
            <input type="text" name="user_location" id="location" placeholder="Enter Your Location" required></input>
            <input type="number"name="user_mobile" id="mobile" placeholder="Enter Your Contact Number" required></input>
            </div>
            <br></br>
            <div className={ classes.selectgap}>
            <select name="user_profile" id="profile" required>
                <option  >Select Profile</option>
                <option>Salaried</option>
                <option>Self Employed</option>
                <option>Professional</option>
                <option>Other</option>

            </select>
            
            <select name="user_service" id="service" required>
                <option >Select Service</option>
                <option>Home Loan</option>
                <option>Loan Against Property</option>
                <option>Personal Loan</option>
                <option>Unsecured Buisness Loan</option>
                <option>SME Loan</option>
                <option>Loan for Purchase of Commercial Properties</option>

            </select>
            </div>
            <br></br>
           <div className={classes.message}> <input  type="textarea"name="user_message" placeholder="Write Your Message Here" id="msg"></input></div>
            <br></br>
            <button type="submit" value="Send" name="submit" onClick={message} ><p>Submit</p></button>
            <div className={classes.messages}>
                <div className={classes.success} id="success">Success ! Thank you For Contacting us we will contact you within 24 hours.</div>
                <div className={classes.danger} id="danger">Feilds Can't be Empty !</div>
            </div>


        </div>
        </form>
        </div>
    </div>
    );
};
export default Contact;