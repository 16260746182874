import Footer from "../../Footer";
import Header from "../../Header";
import Navbar from "../../Navbar";
import classes from "./ConstructionFinance.module.css";
import image from "./asset/construction-finance.jpg";

function ConstructionFinance(){
    return(
        <div className={classes.container1}>
            <Header/>
            <Navbar/>
            <div className={classes.bg}>
                <h1>Construction Finance</h1>
            </div>
        <div className={classes.container}>
            <div className={classes.left}>
                <h1>Introduction</h1>
                <p>Shaurya Enterprises offers Construction Finance to Real Estate Developers for projects in select cities.

Our experience and expertise enable us to identify promising developers and projects which we are happy to finance. Our products enable the developers to meet their working capital needs throughout the life-cycle of the project, right from land acquisition to construction finance including take out.

Customized solutions are offered by in-house, experience team of professionals with good understanding of the Industry. Our team of specialists will help you select the right product, the adequate loan amount, tenure, moratorium, repayment options depending on stage, status & nature of your proposed project and assist you in your requirements throughout this process.

</p>
<div className={classes.key}>
    <h4>Key Benefits:</h4>
    <p><i class="fa-regular fa-hand-point-right"></i> Customized solutions</p>
    <p><i class="fa-regular fa-hand-point-right"></i> End to end solutions through retail home loans with Retail team</p>
    <p><i class="fa-regular fa-hand-point-right"></i> Efficient processing</p>
    <p><i class="fa-regular fa-hand-point-right"></i> Competitive interest rates</p>
</div>
            </div>
            <div className={classes.right}>
                <img src={image} alt="" />
            </div>
        </div>
        <Footer/>
        </div>
    );
};
export default ConstructionFinance;