import classes from "./OurServices.module.css";
import { Link } from "react-router-dom";





function OurServices (){
    return(
<div className={classes.container}>
<div className={classes.heading}>
        <h1>Our Services</h1>
    <div className={classes.linehlder}><span className={classes.divBar}></span> </div>   
    <div className={classes.linehlder}> <p>Shaurya Enterprises is led by expert professionals who have more than 9+ years experience in the market. We are Authorized Business Associates with leading banks and the top financial institutions. Our monthly disbursement run rate is upwards of Rs 20 cr & 100 Files.</p></div>
       
    </div>
    <div className={classes.row}>

    <div className={classes.card}>
        <div className={classes.ico}><i class="fa-sharp fa-solid fa-building"></i></div>   
        <div className={classes.name}><p>Loan Against Property</p></div>
        <div className={classes.text}><p>Aspirations to continually grow/expand one's business are what define most businessmen..</p></div>
        <button><Link to="/loanagainstproperty">Read More</Link></button>
    </div>
    <div className={classes.card}>
        <div className={classes.ico}><i class="fa-solid fa-hand-holding-dollar"></i></div>   
        <div className={classes.name}><p>SME Loans</p></div>
        <div className={classes.text}><p>At Shaurya Enterprises, we understand how important investment is to ensure that businesses reach their full potential..</p></div>
        <button><Link to="/smeloan">Read More</Link></button>
    </div>
    <div className={classes.card}>
        <div className={classes.ico}><i class="fa-sharp fa-solid fa-house-user"></i></div>   
        <div className={classes.name}><p>Home Loan</p></div>
        <div className={classes.text}><p>They say your home is where your story begins. At Shaurya Enterprises, we pride ourselves in helping you begin this story as conveniently as can be..</p></div>
        <button><Link to="/homeLoan">Read More</Link></button>
    </div>
    <div className={classes.card}>
        <div className={classes.ico}><i class="fa-solid fa-user-tie"></i></div>   
        <div className={classes.name}><p>Personal Loan</p></div>
        <div className={classes.text}><p>A personal loan is a type of loan that helps you to tide over a difficult situation when you need funds pronto. A wedding in the family..</p></div>
        <button><Link to="/personalLoan">Read More</Link></button>
    </div>
    <div className={classes.card}>
        <div className={classes.ico}><i class="fa-regular fa-credit-card"></i></div>   
        <div className={classes.name}><p>Credit Card</p></div>
        <div className={classes.text}><p>What is loan against credit card?A loan against credit card works like a personal loan..</p></div>
        <button><Link to='/creditCLone'>Read More</Link></button>
    </div>
    <div className={classes.card}>
        <div className={classes.ico}><i class="fa-solid fa-hand-holding-medical"></i></div>   
        <div className={classes.name}><p>General Insurance</p></div>
        <div className={classes.text}><p>There are different types of General Insurance Policies offered by Shaurya Enterprises..</p></div>
        <button><Link to="/generalInsurance">Read More</Link></button>
    </div>
    <div className={classes.card}>
        <div className={classes.ico}><i class="fa-solid fa-heart-pulse"></i></div>   
        <div className={classes.name}><p>Life Insurance</p></div>
        <div className={classes.text}><p>Everyone knows why life insurance is important, but many of us choose to ignore the reality of what would happen if we were to die suddenly without the proper protection..</p></div>
        <button><Link to="/lifeInsurance">Read More</Link></button>
    </div>
    <div className={classes.card}>
        <div className={classes.ico}><i class="fa-solid fa-city"></i></div>   
        <div className={classes.name}><p>Construction Finance</p></div>
        <div className={classes.text}><p>Shaurya Enterprises offers Construction Finance to Real Estate Developers for projects in select cities..</p></div>
        <button><Link to='/constructionFinance'>Read More</Link></button>
    </div>
    <div className={classes.card}>
        <div className={classes.ico}><i class="fa-solid fa-handshake-simple"></i></div>   
        <div className={classes.name}><p>Loan for Purchase of Commercial Properties</p></div>
        <div className={classes.text}><p>Presenting Commercial Property Purchase Loans for purchase of ready/ under construction commercial property..</p></div>
        <button><Link to='/commertialProperty'>Read More</Link></button>
    </div>
    <div className={classes.card}>
        <div className={classes.ico}><i class="fa-solid fa-briefcase"></i></div>   
        <div className={classes.name}><p>Unsecured Business Loan</p></div>
        <div className={classes.text}><p>Grow your small firm or large-scale company with business loans from HDFC Bank. Our business loans come with a host of benefits..</p></div>
        <button><Link to="/unsecuredBuisnessLoan">Read More</Link></button>
    </div>
    
    </div>
    </div>
    );
};
export default OurServices;