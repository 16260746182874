import classes from "./ChoicePartner.module.css";

import image1 from "./Partners/aditya-birla.png";
import image2 from "./Partners/axis-bank.png";
import image3 from "./Partners/idfcfirstbank.png";
import image4 from "./Partners/dbs.png";
import image5 from "./Partners/sbi.png";
import image6 from "./Partners/fedral-bank.png";
import image7 from "./Partners/bankofbaroda.png";
import image8 from "./Partners/Central_Bank_of_India_Logo.png";
import image9 from "./Partners/hdfc.png";
import image10 from "./Partners/icici.png";
import image11 from "./Partners/Pnb-Housing-Finance-Logo-Vector.png";
import image12 from "./Partners/indiabulls.png";
import image13 from "./Partners/IDBI-Bank-logo.png";
import image14 from "./Partners/kotak.png";
import image15 from "./Partners/Bank-of-India.png";
import image16 from "./Partners/piramal-house.png";
import image17 from "./Partners/rbl.png";
import image18 from "./Partners/shreeram_housing_finance.png";
import image19 from "./Partners/seb.png";
import image20 from "./Partners/tata.png";
import image21 from "./Partners/yes-bank.png";
import image22 from "./Partners/Au_finance.png";



function ChoicePartner(){
    return(
        <div className={classes.container}>
            <div className={classes.head}><h1>Choice of Partners With Us</h1></div>
            <div className={classes.linehlder}><span className={classes.divBar}></span></div>
            <div className={classes.row}>
                <img src={image1} alt="" ></img>
                <img src={image2} alt="" ></img>
                <img src={image3} alt="" ></img>
                
                <img src={image5} alt="" ></img>
                <img src={image6} alt="" ></img>
                <img src={image7} alt="" ></img>
                <img src={image8} alt="" ></img>
                <img src={image9} alt="" ></img>
                <img src={image10} alt="" ></img>
                <img src={image11} alt="" ></img>
                <img src={image12} alt="" ></img>
                <img src={image13} alt="" ></img>
                <img src={image14} alt="" ></img>
                <img src={image15} alt="" ></img>
                <img src={image16} alt="" ></img>
                <img src={image17} alt="" ></img>
                <img src={image18} alt="" ></img>
                <img src={image19} alt="" ></img>
                <img src={image20} alt="" ></img>
                <img src={image21} alt="" ></img>
                <img src={image22} alt="" ></img>
                
            </div>
        </div>

    );
};
export default ChoicePartner;