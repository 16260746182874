import Footer from "../../Footer";
import Header from "../../Header";
import Navbar from "../../Navbar";
import classes from "./LifeInsurance.module.css";
import image from "./asset/lifeinsurance.jpg";

function LifeInsurance(){
    return(
        <div className={classes.container}>
            <Header/>
            <Navbar/>
            <div className={classes.bg}>
                <h1>Life Insurance</h1>
            </div>
            <div className={classes.main}>
                <div className={classes.left}>
                    <h1>Introduction</h1>
                    <p>Everyone knows why life insurance is important, but many of us choose to ignore the reality of what would happen if we were to die suddenly without the proper protection. The results could be devastating for your surviving family members, leaving them with a large bill for your final expenses, or even worse, loss of the family home because they can no longer cover the mortgage.

<br></br>If you don’t currently have a life insurance policy, get one for you and your family today.</p>
                </div>
                <div className={classes.right}>
                    <img src={image} alt="" />
                </div>
                
            </div>
            <Footer/>
        </div>

    );
};
export default LifeInsurance;