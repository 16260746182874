import { Link } from "react-router-dom";
import ContactText from "./ContactText";
import Footer from "./Footer";
import Header from "./Header";
import Navbar from "./Navbar";
import classes from "./Thankyou.module.css";

function  Thankyou (){
    return(
        
     <div className={classes.container}>
       <Header/>
       <Navbar/>
       <div className={classes.container2}>
       <div className={classes.bg}></div>
       <div className={classes.heading}><h1>Thank You </h1></div>

       <div className={classes.thankyouMsg}><p>Success! Thank you for the message. We will contact you within 24 Hours.</p></div>

       <div className={classes.hmbtn}><button ><Link to ="/" className={classes.noul}><p>GO Back Home</p></Link></button></div>
     </div>

     <ContactText/>
     <br></br>
     <br></br>
     <br></br>
     <Footer/>
     </div>
    );
};
export default Thankyou;
