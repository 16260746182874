import classes from "./Header.module.css";
function Header (){
    return(
     <div className={classes.main}>
        <div className={classes.email} ><a href="mailto:info@shauryaenterprisesfin.com" className={classes.tdecor}><i className="fa-solid fa-envelope"></i> info@shauryaenterprisesfin.com</a></div>
        <div className={classes.mobile}><a href="tel:+91 8554983997" className={classes.tdecor}><i className="fa-solid fa-phone"></i> +91 8554983997</a></div>
        <div className={classes.time} > |&nbsp; Mon to Sat , 10am to 6pm</div>
     </div>
    );
};
export default Header;