import classes from './Card.module.css';
import star from './asset/star.png';

const Card = (props) => {
    return (
        <div className={classes.comtain}>
        <div className={classes.hold}>
            <img src={star} alt='' className={classes.star}></img>
            <p className={classes.head}>{props.head}</p>
            <p className={classes.txt}>{props.txt}</p>
            
        </div>
        </div>
    )
}

export default Card;