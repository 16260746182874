import Header from "../../Header";
import Navbar from "../../Navbar";
import classes from "./UnsecuredBL.module.css";
import image from "./asset/business_loan.jpg";
import { useState } from 'react';
import './Acc.css';
import icon from './asset/next.png';
import Footer from "../../Footer";


function UnsecuredBL(){
    const [r1, setR1] = useState(true);
    const [r2, setR2] = useState(false);
    const [r3, setR3] = useState(false);
    const [r4, setR4] = useState(false);
    const [r5, setR5] = useState(false);

    const clkHnd1 = () => {
        setR1(true);
        setR2(false);
        setR3(false);
        setR4(false);
        setR5(false);
    };

    const clkHnd2 = () => {
        setR1(false);
        setR2(true);
        setR3(false);
        setR4(false);
        setR5(false);
    };

    const clkHnd3 = () => {
        setR1(false);
        setR2(false);
        setR3(true);
        setR4(false);
        setR5(false);
    };

    const clkHnd4 = () => {
        setR1(false);
        setR2(false);
        setR3(false);
        setR4(true);
        setR5(false);
    };

    const clkHnd5 = () => {
        setR1(false);
        setR2(false);
        setR3(false);
        setR4(false);
        setR5(true);
    };
    return(
        <div className={classes.allOne}>
            <Header/>
            <Navbar/>
            <div className={classes.bg}>
                <h1>Unsecured Business Loan</h1>
            </div>
            
            <div className={classes.main}>
                <div className={classes.left}>
                    <h1>Introduction</h1>
                    <p>Unsecured Business Loans are the loans which does not require any property as collateral for the loan. This loan solely depends on the creditworthiness of the borrower. Business loans are the loans provided to you on behalf of your business idea. Unsecured business loan is similar to a personal loan.This loan helps for a start-up or for the expansion of an existing business. This loan is specially designed to meet all the business requirements.<br></br>

Unsecured funds do not run on a term or a fixed end date. You do have to meet certain sanction rules. Payments should be made as per the agreed terms. By recognizing these terms it gives you access to unsecured loans as per your need. In these loans, financial institute always looks for the source of income and other financial resources. Because it gives a guarantee to that financial institution regarding the payment.<br></br>

Unsecured loans can be somewhat similar to Credit card purchases, personal loans, Student loans, etc. Unsecured Loan is a risk-free loan for the Applicant because as compared to other loans he does not hand over any of his/her assets to the bank.<br></br>

Unsecured loans are usually used for expansion of current business or to initiate a new business. If the banking institute finds the potential in your business idea then you definitely get the loan. This type of loan plays a key role to support start-up. Start-up generally lacks funds for their establishment. So unsecured loans provide a foundation for their establishment. Clients need to get his business idea regarding a new establishment or development of current business crystal clear. Financial institutes leave the spending responsibilities to the business owner as long as the money is used for business expenses the floor is yours.</p>
                </div>
                <div className={classes.right}>
                    <img src={image} alt="" />
                </div>
            </div>
            <div className='AccHold'>
            <div className='left'>
                <button className={r1 ? 'AccBtn1 AccBtn11' : 'AccBtn'} onClick={clkHnd1}>Benifits of Unsecured Loan</button>
                <button className={r2 ? 'AccBtn1' : 'AccBtn'} onClick={clkHnd2}>Why Shaurya Enterprises ?</button>
                <button className={r3 ? 'AccBtn1' : 'AccBtn'} onClick={clkHnd3}>How Can You Apply With Shaurya?</button>
                <button className={r4 ? 'AccBtn1' : 'AccBtn'} onClick={clkHnd4}>Eligibility Criteria</button>
                <button className={r5 ? 'AccBtn1' : 'AccBtn'} onClick={clkHnd5}>Documentation Required</button>
            </div>
            <div className='right'>

                {r1 && <div className='right1'>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>No Collateral :Yes you that's right you don't require any collateral for unsecured business loan. That means no risking your property to secure your dreams</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Easy application process</p>
                    </div>
                    
                    
                </div>}

                {r2 && <div className='right2'>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Easy to obtain</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Maximum Loan Sanction</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Hassle free documentation</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>No spending restriction on the loan</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Better interest rates</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>No collateral needed</p>
                    </div>
                </div>}

                {r3 && <div className='right3'>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Go to www.shauryaenterprisesfin.com</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Click on Unsecured Business Loans.</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Fill the form and submit it.</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Our representative will contact you shortly to arrange a meeting with you and collect the documents for further processing.</p>
                    </div>
                </div>}

                {r4 && <div className='right3'>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Self-employed individuals, Proprietors, Private Ltd. Co. and Partnership Firms involved in the business of Manufacturing, Trading or Services.</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Individuals who have been in the current business for a minimum of 3 years, with 5 years total business experience.</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Those whose business has been profit making for the previous 2 years</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>The business should have a Minimal Annual Income (ITR) of Rs. 1.5 lakhs per annum</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>The applicant should be at least 21 years at the time of applying for the loan, and should be no older than 65 years at the time of loan maturit</p>
                    </div>
                </div>}

                {r5 && <div className='right3'>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>ID Proof: Any Two from Passport, Pan card, Voters ID Card and Driving License..</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Address Proof</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Proof of Continuity of Business: - 5 years old banking page, TIN/VAT RC, Service Tax regn.</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Bank Statement 6 Months</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>ITR & Statement Of Computation of Income – for last 3 Years</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>CA Certified or Audited P/L Account and for turnover greter than 60 Lacs cases Audit report along with schedules of P&L and Balance Sheet – for last 3 years.</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Ownership Proof of Residence or Office</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>All running loan tracks or schedule.</p>
                    </div>
                </div>}
            </div>
        </div>

        <Footer/>


        </div>

    );
};
export default UnsecuredBL;