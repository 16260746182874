import classes from "./ContactAdd.module.css";

function ContactAdd(){
    return(
        <div className={classes.container}>
            <div className={classes.bg}></div>
            <div className={classes.heading}><h1>Contact Us</h1></div>
        </div>

    );
};
export default ContactAdd;