import classes from "./Hero.module.css";
import image1 from "./Images/hero1.jpg";

function Hero (){
    return(
        <div className={classes.container}>
          <div className={classes.heroImage}><img src={image1} alt="Hero-image1"></img></div>
          </div>
    );
};
export default Hero;