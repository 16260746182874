import { useState } from 'react';
import './Acc.css';
import icon from './asset/next.png';

const Acc = () => {
    const [r1, setR1] = useState(true);
    const [r2, setR2] = useState(false);
    const [r3, setR3] = useState(false);
    const [r4, setR4] = useState(false);
    const [r5, setR5] = useState(false);

    const clkHnd1 = () => {
        setR1(true);
        setR2(false);
        setR3(false);
        setR4(false);
        setR5(false);
    };

    const clkHnd2 = () => {
        setR1(false);
        setR2(true);
        setR3(false);
        setR4(false);
        setR5(false);
    };

    const clkHnd3 = () => {
        setR1(false);
        setR2(false);
        setR3(true);
        setR4(false);
        setR5(false);
    };

    const clkHnd4 = () => {
        setR1(false);
        setR2(false);
        setR3(false);
        setR4(true);
        setR5(false);
    };

    const clkHnd5 = () => {
        setR1(false);
        setR2(false);
        setR3(false);
        setR4(false);
        setR5(true);
    };

    return (
        <div className='AccHold'>
            <div className='left'>
                <button className={r1 ? 'AccBtn1 AccBtn11' : 'AccBtn'} onClick={clkHnd1}>Product Feature</button>
                <button className={r2 ? 'AccBtn1' : 'AccBtn'} onClick={clkHnd2}>Why Shaurya Enterprises ?</button>
                <button className={r3 ? 'AccBtn1' : 'AccBtn'} onClick={clkHnd3}>How Can You Apply With Shaurya?</button>
                <button className={r4 ? 'AccBtn1' : 'AccBtn'} onClick={clkHnd4}>Eligibility Criteria</button>
                <button className={r5 ? 'AccBtn1' : 'AccBtn'} onClick={clkHnd5}>Documentation Required</button>
            </div>
            <div className='right'>

                {r1 && <div className='right1'>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Collateral free business loans</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Right Sized Loans</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Loans from 2 Lacs - 30 Lacs</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Flexible Tenure</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Easy loan repayment options</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Fast & Simple</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Long Tenure upto 24 Months</p>
                    </div>
                </div>}

                {r2 && <div className='right2'>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Instant Decision</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Quick Disbursal</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Easy & Hassle Free</p>
                    </div>
                </div>}

                {r3 && <div className='right3'>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Go to www.shauryaenterprisesfin.com</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Click on SME Loans.</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Fill the form and submit it.</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Our representative will contact you shortly to arrange a meeting with you and collect the documents for further processing.</p>
                    </div>
                </div>}

                {r4 && <div className='right3'>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Self-employed individuals, Proprietors, Private Ltd. Co. and Partnership Firms involved in the business of Manufacturing, Trading or Services.</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Individuals who have been in the current business for a minimum of 3 years, with 5 years total business experience.</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Those whose business has been profit making for the previous 2 years</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>The business should have a Minimal Annual Income (ITR) of Rs. 1.5 lakhs per annum</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>The applicant should be at least 21 years at the time of applying for the loan, and should be no older than 65 years at the time of loan maturit</p>
                    </div>
                </div>}

                {r5 && <div className='right3'>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>ID Proof: Any Two from Passport, Pan card, Voters ID Card and Driving License..</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Address Proof</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Proof of Continuity of Business: - 5 years old banking page, TIN/VAT RC, Service Tax regn.</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Bank Statement 6 Months</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>ITR & Statement Of Computation of Income – for last 3 Years</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>CA Certified or Audited P/L Account and for turnover greter than 60 Lacs cases Audit report along with schedules of P&L and Balance Sheet – for last 3 years.</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Ownership Proof of Residence or Office</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>All running loan tracks or schedule.</p>
                    </div>
                </div>}
            </div>
        </div>

    )
}

export default Acc;