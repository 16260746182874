import { Routes,Route } from 'react-router-dom';
import './App.css';

import Home from './Routes/Home';
import About from './Routes/About';
import OurServicess from './Routes/OurServicess';
import EmiCalculator from './Routes/EmiCalculator';
import ContactPage from './Routes/ContactPage';
import Thankyou from './Components/Thankyou';
import Sme from './Components/Service/Service/Sme';
import ConstructionFinance from "./Components/Service/Service/ConstructionFinance";
import LifeInsurance from './Components/Service/Service/LifeInsurance';
import UnsecuredBL from './Components/Service/Service/UnsecuredBL';
import PersonalLoan from './Components/Service/Service/PersonalLoan';
import CreditCard from './Components/Service/Service/CreditCard';
import GenralInsurance from './Components/Service/GenralInsurance';
import HomeLoan from './Components/Service/Service/HomeLoan';
import CommertialProperties from './Components/Service/Service/CommertialProperties';
import LoanAgainstProperty from './Components/Service/Service/LoanAgainstProperty';
import BuisnessPartnerProgram from './Components/BuisnessPartnerProgram';


function App() {
  return (
    <div className="App">
     <Routes>
      <Route path="/" element ={<Home/>}  />
      <Route path="/about" element ={<About/>}  />
      <Route path="/ourservices" element ={<OurServicess/>}  />
      <Route path="/emicalculator" element ={<EmiCalculator/>}  />
      <Route path="/contact" element ={<ContactPage/>}  />
      <Route path="/thankYou" element={<Thankyou />} />
      <Route path="/smeloan" element={<Sme />} />
      <Route path="/constructionFinance" element={<ConstructionFinance />} />
      <Route path="/lifeInsurance" element={<LifeInsurance />} />
      <Route path="/unsecuredBuisnessLoan" element={<UnsecuredBL/>} />
      <Route path="/personalLoan" element={<PersonalLoan/>} />
      <Route path="/creditCLone" element={<CreditCard/>} />
      <Route path="/generalInsurance" element={<GenralInsurance/>} />
      <Route path="/homeLoan" element={<HomeLoan/>} />
      <Route path="/commertialProperty" element={<CommertialProperties/>} />
      <Route path="/loanagainstproperty" element={<LoanAgainstProperty/>} />
      <Route path="/buisnesspartnerprogram" element={<BuisnessPartnerProgram/>} />
     </Routes>
     </div>
  );
}

export default App;
